.overdue {
    color: #c00c !important;
}

.compactText {
    color: #004c;
    font-size: 10pt;
    display: flex;
    align-items: center;
    white-space: nowrap;
}