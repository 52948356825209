html, body {
  margin: 0;
  height: 100%;
}

#root:before {
	height: 100%;
	background-image: url('./images/woodgrain.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	position: fixed;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -100;
}

#root .MuiButton-textPrimary {
	color: white;
}

#fade .MuiInputBase-input {
	padding: 0px;
}

.MuiDrawer-paper {
	width: 100vw;
}

.MuiListItem-container {
	list-style: none;
}

.fade-enter {
	opacity: 0.01;
	margin-top: -23px;
	margin-bottom: -23px;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	margin-top: 0px;
	margin-bottom: 0px;
	transition: opacity 300ms ease-in, margin-bottom 300ms ease-in, margin-top 300ms ease-in;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0.01;
	margin-top: -23px;
	margin-bottom: -23px;
	transition: opacity 300ms ease-in, margin-bottom 300ms ease-in, margin-top 300ms ease-in;
}