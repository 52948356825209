.TodoApp {
	margin: 10px;
}

.TodoApp-header {
	text-align: center;
}

.App-header input {
	text-align: left;
}
